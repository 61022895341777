import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from './components/Spinner';

function Reverso() {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [countdown, setCountdown] = useState(5);
  const [validationCorrect, setValidationCorrect] = useState(false);
  const [capturedPhotos, setCapturedPhotos] = useState(0);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const saveData = (data) => {
    localStorage.setItem('capturedImageData', data);
  };

  const captureImage = async () => {
    if (capturedPhotos >= 10) {
      return;
    }
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' }, audio: false});
    video.srcObject = stream;
    video.play();
    setLoading(true);
    await new Promise(resolve => setTimeout(resolve, 6000));
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
    const imageData = canvas.toDataURL('image/png');
    saveData(imageData);


    const responseApi1 = await fetch('https://apinetrial.toi.com.mx/INE_atras', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ image: imageData }),
    });
    const dataApi1 = await responseApi1.json();

    if (dataApi1.validation === 1) {
      setValidationCorrect(true);
      setTimeout(() => {
        navigate('/SelfieIne', { replace: true });
      }, 2000);
    } else {
      setCountdown(5);
      setCapturedPhotos(prevCapturedPhotos => prevCapturedPhotos + 1);
      setLoading(false);
      setTimeout(captureImage, 2000);
    }
  };

  useEffect(() => {
    document.addEventListener("DOMContentLoaded", function() {
      let video = document.getElementById('vid');
      video.style.width = document.documentElement.clientWidth + 'px';
      video.style.height = document.documentElement.clientHeight + 'px';
      video.setAttribute('autoplay', '');
      video.setAttribute('muted', '');
      video.setAttribute('playsinline', '');

      let constraints = {
        audio: false,
        video: {
          facingMode: 'environment'
        }
      }

      navigator.mediaDevices.getUserMedia(constraints).then(function success(stream) {
        video.srcObject = stream;
        stream.getTracks().forEach(t => {
          t.stop();
          stream.removeTrack(t);
        });
      });
    });
  }, []);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1);
    }, 1000);
    return () => clearInterval(countdownInterval);
  }, []);

  useEffect(() => {
    captureImage();

  }, []);

  return (
    <div className="reverso">
      <div className="main-container" style={{ fontFamily: 'Helvetica Neue, Arial, sans-serif' }}>
        <h1>CAPTURE REVERSO</h1>
        <p>Parte trasera de la INE</p>
        <div className="camera-container" style={{ position: 'relative' }}>
          {loading && <Spinner />} {/* Mostrar el spinner si loading es true */}
          <video ref={videoRef} className="camera-viewfinder" muted></video>
          <canvas ref={canvasRef} className="camera-capture"></canvas>
          {countdown > 0 && (
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: '4rem',
                fontWeight: 'bold',
                color: '#fff',
                textShadow: '1px 1px #000',
              }}
            >
              {countdown}
            </div>
          )}
          {validationCorrect && (
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: '10rem',
                fontWeight: 'bold',
                color: '#00FF00',
              }}
            >
              &#10003;
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Reverso;
