import React from "react";
import checkLogo from "./images/checkLogo.png";

function Finalizar() {
  return (
    <div className="app">
      <div
        className="main-container"
        style={{ fontFamily: "Helvetica Neue, Arial, sans-serif" }}
      >
        <img src={checkLogo} alt="Check" className="logo" />
        <div className="title">¡Gracias por realizar el proceso!</div>
        <div className="message">Ya puedes cerrar esta ventana.</div>
      </div>
    </div>
  );
}

export default Finalizar;