import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from './components/Spinner';

function SelfieIne() {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [countdown, setCountdown] = useState(5);
  const [validationCorrect, setValidationCorrect] = useState(false);
  const [capturedPhotos, setCapturedPhotos] = useState(0);
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate();

  const saveData = (data) => {
    localStorage.setItem('capturedImageData', data);
  };

  const captureImage = async () =>{
    if (capturedPhotos >=10){
      return;
    }
    const video = videoRef.current;
    const canvas = canvasRef.current;
    try {
      
      const stream2 = await navigator.mediaDevices.getUserMedia({video: true, audio: false});
      // stream2.getTracks().forEach(t => {
      //   t.stop();
      //   stream2.removeTrack(t);
      // });
      video.srcObject = stream2;
      video.play();
    } catch (error) {
      alert("Error al acceder a la cámara: " + error.message);
    }
      setLoading(true)
      await new Promise(resolve => setTimeout(resolve, 6000));
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
      const imageData = canvas.toDataURL('image/png');
      saveData(imageData);
      
    const responseApi1 = await fetch('https://apinetrial.toi.com.mx/INE_persona', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ image: imageData }),
    });
    const dataApi1 = await responseApi1.json();

    if (dataApi1.validation === 1) {
      setValidationCorrect(true);
      setTimeout(() => {
        navigate('/Finalizar', { replace: true });
      }, 2000);
    } else {
      setCountdown(5);
      setCapturedPhotos(prevCapturedPhotos => prevCapturedPhotos + 1);
      setLoading(false)
      setTimeout(captureImage, 2000);
    }
  };

  useEffect(() => {
    document.addEventListener("DOMContentLoaded", function() {
      let video = document.getElementById('vid');
      video.style.width = document.documentElement.clientWidth + 'px';
      video.style.height = document.documentElement.clientHeight + 'px';
      video.setAttribute('autoplay', '');
      video.setAttribute('muted', '');
      video.setAttribute('playsinline', '');

      let constraints = {
        audio: false,
        video: {
          facingMode: {exact: "user"}
        }
      }

      navigator.mediaDevices.getUserMedia(constraints).then(function success(stream2) {
        video.srcObject = stream2;
      });
    });
  }, []);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1);
    }, 1000);
    return () => clearInterval(countdownInterval);
  }, []);

  useEffect(() => {
    captureImage();
  }, []);

  return (
    <div className="selfieIne">
      <div className="main-container" style={{ fontFamily: 'Helvetica Neue, Arial, sans-serif' }}>
        <h1>SELFIE CON INE</h1>
        <p>Por favor, toma una foto de tu rostro con tu INE</p>
        <div className="camera-container" style={{ position: 'relative' }}>
        {loading && <Spinner />}
          <video ref={videoRef} className="camera-viewfinder" muted></video>
          <canvas ref={canvasRef} className="camera-capture"></canvas>
          {countdown > 0 && (
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: '4rem',
                fontWeight: 'bold',
                color: '#fff',
                textShadow: '1px 1px #000',
              }}
            >
              {countdown}
            </div>
          )}
          {validationCorrect && (
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: '10rem',
                fontWeight: 'bold',
                color: '#00FF00',
              }}
            >
              &#10003;
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SelfieIne;