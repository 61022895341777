import React from "react";
import createRoot from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import "./App.css";
import Reverso from "./Reverso.js";
import SelfieIne from "./SelfieIne.js";
import Finalizar from "./Finalizar.js";
import Logo from "./images/toi_logo.png";

function AppContainer() {
  return (
    <div>
      <header>
        <img src={Logo} alt="Logo de Toi" className="logo" />
      </header>

      <Routes>
        <Route exact path="/" element={<App />} />
        <Route path="/reverso" element={<Reverso />} />
        <Route path="/selfieIne" element={<SelfieIne />} />
        <Route path="/Finalizar" element={<Finalizar />} />
      </Routes>
    </div>
  );
}

createRoot.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppContainer />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
